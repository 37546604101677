<template>
    <div class="root-wrap">
        <div class="header-wrap">
            <form action="/">
                <van-search v-model="cityName" placeholder="搜索城市名" shape="round" @input="onSearch"></van-search>
            </form>
        </div>
        <div class="body-wrap fx-fill">
            <div class="fill-box fx-column">
                <div class="city_item-wrap">
                    <div class="city_title">定位城市</div>
                    <van-cell :title="locationName" clickable @click="doSelectLocation(location)">
                        <div class="fx-end fx-column">
                            <div class="fx-row fx-center" @click.stop="doReposition">
                                <van-icon :name="require('@/assets/images/position_user.png')" size="20" :class="positioning ? 'positioning' : ''"></van-icon>
                                <span :style="`color:${$config.style.secondColor}`">{{positioning ? '定位中...' : '重新定位'}}</span>
                            </div>
                        </div>
                    </van-cell>
                </div>
                <div class="city_item-wrap">
                    <div class="city_title">当前选择城市</div>
                    <van-cell :title="selectedLocationName" clickable @click="doSelectLocation(selectedLocation)"></van-cell>
                </div>
                <div class="city_item-wrap" style="padding-bottom: 15px;">
                    <div class="city_title">热门城市</div>
                    <div class="city_list-wrap">
                        <div v-for="(item, index) in hotCityList" :key="index" clickable @click="doSelectLocation(item)">{{item.name}}</div>
                    </div>
                    <!-- <van-cell v-for="(item, index) in hotCityList" :key="index" :title="item.name" clickable @click="doSelectLocation(item)"></van-cell> -->
                </div>
                <div class="city_item-wrap fx-fill">
                    <van-index-bar :index-list="cityIndexList">
                        <template v-for="(item, index) in cityInfo">
                            <van-index-anchor :index="item.capital" :key="index">{{item.capital}}</van-index-anchor>
                            <van-cell v-for="(temp) in item.city" :key="temp.code" clickable @click="doSelectLocation(temp)">{{temp.name}}</van-cell>
                        </template>
                    </van-index-bar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { buildCityList, getUserLocation } from '@/libs/map-utils.js';
export default {
    data() {
        return {
            cityName: '',
            cityIndexList: [],
            cityInfo: [],
            hotCityList: [],
            positioning: false,
        }
    },
    computed: {
        location() {
            return this.$store.state.app.location;
        },
        locationName() {
            return this.$store.state.app.location ? this.$store.state.app.location.name : '';
        },
        selectedLocation() {
            return this.$store.state.app.selectedLocation;
        },
        selectedLocationName() {
            return this.$store.state.app.selectedLocation ? this.$store.state.app.selectedLocation.name : this.$config.defaultLocation.name;
        }
    },
    methods: {
        onSearch(value) {
            this.getCityList(value);
        },
        doSelectLocation(item) {
            this.$store.commit('setSelectedLocation', item);
            this.$goPage(-1);
        },
        getCityList(keyword) {
            buildCityList().then((cityList) => {
                this.hotCityList = cityList.filter(item => item.ishot === true).map(self => { return { name: self.name, cityCode: self.cityCode } }).slice(0, 9);
                if (keyword) {
                    cityList = cityList.filter(item => item.name.indexOf(keyword) > -1);
                }
                let tempArr = cityList.map(item => item.pinyin.substr(0, 1));
                let searchLetter = Array.from(new Set(tempArr))

                this.cityIndexList = searchLetter;
                this.cityInfo = searchLetter.map(item => ({ capital: item, city: cityList.filter(self => self.pinyin[0] === item).map(self => { return { name: self.name, cityCode: self.cityCode } }), id: item }))
            });
        },
        doReposition() {
            this.positioning = true;
            getUserLocation().then(() => {
                this.positioning = false;
            }).catch(() => {
                this.positioning = false;
            });
        }
    },
    mounted() {
        // getUserLocation();
        this.getCityList();
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	height: 100%;
	overflow-y: scroll;
	.city_item-wrap {
		background-color: #fff;
		.city_title {
			font-size: 15px;
			color: #818181;
			padding: 10px;
			padding-bottom: 0px;
			background-color: #fff;
		}
		.city_item {
			background-color: #fff;
			padding: 0 30px;
		}

		.city_list-wrap {
			background-color: #fff;
			display: grid;
			grid-template-columns: repeat(3, 25%);
			grid-row-gap: 8px;
			justify-content: space-around;
            padding: 10px 0;
			> div {
				border-radius: 3px;
				color: #777;
				border: 1px solid red;
				font-size: 14px;
				text-align: center;
				line-height: 28px;
				background-color: #eff1f8;
				border: 1px solid #eee;
				// box-shadow: 1px 2px 10px rgba(27, 26, 25, 0.2);
			}
		}
	}
}

/deep/ .positioning {
	animation: turn 1.2s linear infinite;
}

@keyframes turn {
	0% {
		-webkit-transform: rotate(0deg);
	}
	25% {
		-webkit-transform: rotate(90deg);
	}
	50% {
		-webkit-transform: rotate(180deg);
	}
	75% {
		-webkit-transform: rotate(270deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
</style>
